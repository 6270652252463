import PropTypes from "prop-types";

export const ITINERARY_PROPS_FROM_SDP_API = PropTypes.shape({
  map_image_url: PropTypes.string,
  stop_set: PropTypes.arrayOf(
    PropTypes.shape({
      day_offset: PropTypes.number,
      description: PropTypes.string,
      display_name: PropTypes.string,
      image_url: PropTypes.string,
      image_url_small: PropTypes.string,
      image_url_medium: PropTypes.string,
      image_url_large: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      arrival_time: PropTypes.string,
      departure_time: PropTypes.string,
    })
  ),
});

export const CRUISELINE_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const HEADER_PROPS_FROM_API = PropTypes.shape({
  cruiselines: PropTypes.arrayOf(CRUISELINE_SHAPE),
  departure_ports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.shape({
        abbreviation: PropTypes.string,
        name: PropTypes.string,
        country_id: PropTypes.string,
      }),
      country: PropTypes.shape({
        abbreviation: PropTypes.string,
        name: PropTypes.string,
      }),
      canned_search_urlname: PropTypes.string,
    })
  ),
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      canned_search_urlname: PropTypes.string,
    })
  ),
});

export const FILTER_PROPS_FROM_API = PropTypes.shape({
  cruiselines: PropTypes.arrayOf(CRUISELINE_SHAPE),
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      popular: PropTypes.bool,
    })
  ),
  departure_ports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.shape({
        abbreviation: PropTypes.string,
        name: PropTypes.string,
        country_id: PropTypes.string,
      }),
      country: PropTypes.shape({
        abbreviation: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  months: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      first_day: PropTypes.string,
      last_day: PropTypes.string,
    })
  ),
  room_types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
});

export const SHIP_FACTS_SHAPE = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  })
);

export const PRICE_TYPE = PropTypes.oneOf([
  "lowest",
  "balcony",
  "ocean",
  "suite",
  "inside",
]);

export const PRICE_DROP_SHAPE = PropTypes.shape({
  room_type: PropTypes.string,
  old_price: PropTypes.number,
  new_price: PropTypes.number,
  difference: PropTypes.number,
  percent_change: PropTypes.number,
});

export const PRICE_SHAPE = PropTypes.shape({
  price: PropTypes.number,
  pricePerNight: PropTypes.number,
  deal: PropTypes.string,
  priceDrop: PRICE_DROP_SHAPE,
  memberPrice: PropTypes.number,
});

export const OTHER_SAILING_SHAPE = PropTypes.arrayOf(
  PropTypes.shape({
    balcony_price: PropTypes.number,
    deals: PropTypes.shape({
      inside_deal: PropTypes.string,
      outside_deal: PropTypes.string,
      balcony_deal: PropTypes.string,
      suite_deal: PropTypes.string,
    }),
    display_name: PropTypes.string,
    inside_price: PropTypes.number,
    odysseus_id: PropTypes.number,
    outside_price: PropTypes.number,
    start_date: PropTypes.string,
    suite_price: PropTypes.number,
  })
);

export const SPECIAL_OFFERS_SHAPE = PropTypes.arrayOf(
  PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    is_member_only: PropTypes.bool,
  })
);

export const SHIP_SHAPE = PropTypes.shape({
  cruiseline: PropTypes.shape({
    id: PropTypes.string,
    logo_url: PropTypes.string,
    name: PropTypes.string,
    amenities: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
      })
    ),
  }),
  id: PropTypes.string,
  image_url_large: PropTypes.string,
  image_url_medium: PropTypes.string,
  image_url_fallback: PropTypes.string,
  number_of_guests: PropTypes.number,
  max_guests_per_room: PropTypes.number,
  name: PropTypes.string,
  odysseus_id: PropTypes.number,
  rating: PropTypes.string,
});

export const SAILING_DEAL_SHAPE = PropTypes.shape({
  arrival_port: PropTypes.shape({
    country: PropTypes.shape({
      abbreviation: PropTypes.string,
      name: PropTypes.string,
    }),
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  balcony_price: PropTypes.number,
  deal_scores: PropTypes.object,
  departure_port: PropTypes.shape({
    country: PropTypes.shape({
      abbreviation: PropTypes.string,
      name: PropTypes.string,
    }),
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  display_name: PropTypes.string,
  image_url: PropTypes.string,
  image_url_fallback: PropTypes.string,
  inside_price: PropTypes.number,
  odyssues_id: PropTypes.number,
  outside_price: PropTypes.number,
  ship: SHIP_SHAPE,
  special_offers: SPECIAL_OFFERS_SHAPE,
  suite_price: PropTypes.number,
  url_name: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
});

export const SIMILAR_SAILINGS = PropTypes.arrayOf(
  PropTypes.shape({
    balcony_price: PropTypes.number,
    deal_scores: PropTypes.object,
    display_name: PropTypes.string,
    image_url: PropTypes.shape({
      image_url: PropTypes.string,
      image_url_small: PropTypes.string,
      image_url_medium: PropTypes.string,
      image_url_large: PropTypes.string,
    }),
    inside_price: PropTypes.number,
    odyssues_id: PropTypes.number,
    outside_price: PropTypes.number,
    ship: SHIP_SHAPE,
    special_offers: SPECIAL_OFFERS_SHAPE,
    suite_price: PropTypes.number,
    url_name: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  })
);

export const GALLERY_IMAGES_SHAPE = PropTypes.arrayOf(
  PropTypes.shape({
    url: PropTypes.string,
    url_fallback: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  })
);

export const REF_SHAPE = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any }),
]);

export const SAILING_ITEM_PROPS = PropTypes.shape({
  odysseus_id: PropTypes.number,
  title: PropTypes.string,
  line: PropTypes.string,
  ship: PropTypes.string,
  starRating: PropTypes.string,
  date: PropTypes.string,
  days: PropTypes.string,
  departure_port: PropTypes.string,
  duration: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  inside: PRICE_SHAPE,
  ocean: PRICE_SHAPE,
  balcony: PRICE_SHAPE,
  suite: PRICE_SHAPE,
  link: PropTypes.string,
  image: PropTypes.string,
  imageDescription: PropTypes.string,
  imageFallback: PropTypes.string,
  facts: SHIP_FACTS_SHAPE,
  ports: PropTypes.string,
  logo: PropTypes.string,
  isNewerShip: PropTypes.bool,
  specialOffers: SPECIAL_OFFERS_SHAPE,
  destinationImages: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      imageDescription: PropTypes.string,
      imageFallback: PropTypes.string,
    })
  ),
  shipHighlightFeature: PropTypes.string,
  itinerary: ITINERARY_PROPS_FROM_SDP_API,
});

export const OTHER_SAILINGS_FOR_SAME_ITINERARY_ITEM = PropTypes.shape({
  odysseus_id: PropTypes.number,
  urlname: PropTypes.string,
  inside: PRICE_SHAPE,
  ocean: PRICE_SHAPE,
  balcony: PRICE_SHAPE,
  title: PropTypes.string,
  date: PropTypes.string,
  days: PropTypes.string,
  duration: PropTypes.number,
});

export const FEATURED_DEPARTURE_PORT_SHAPE = PropTypes.shape({
  name: PropTypes.string,
  urlname: PropTypes.string,
});

export const FEATURED_DESTINATION_SHAPE = PropTypes.shape({
  name: PropTypes.string,
  urlname: PropTypes.string,
});

export const FEATURED_REGION_SHAPE = PropTypes.shape({
  name: PropTypes.string,
  urlname: PropTypes.string,
  image_url: PropTypes.string,
  lowest_price: PropTypes.number,
  number_of_sailings: PropTypes.number,
});

export const PARTNERS_SHAPE = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.string,
  svg_url: PropTypes.string,
});

export const PROMOTIONAL_ITEM_SHAPE = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  logo_url: PropTypes.string,
});
