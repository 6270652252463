export function firePageView() {
  if (!window.utag || !window.utag_data) {
    return;
  }

  window.utag.view(window.utag_data);
  // console.info("utag_data after routeCHANGE: ", window.utag_data);
}

const fireTrackingCall = (trackingObject) => {
  if (!window.utag || !window.utag_data) {
    return;
  }

  window.utag.link(trackingObject);

  // Since event_targets will persist on the page we need to manually clear them
  // Otherwise, subsequent events will take a previous event's data
  window.utag_data.event_name = undefined;
  window.utag_data.campaign_id = undefined;
  window.utag_data.placement = undefined;
};

export function trackOpenFilter(placement) {
  fireTrackingCall({
    event_name: "gotosea_search",
    campaign_id: "open filter",
    placement,
  });
}

export function trackViewSailingDetail(placement) {
  fireTrackingCall({
    event_name: "gotosea_search",
    campaign_id: "view sailing detail",
    placement,
  });
}

export function trackSearchFavorite(placement) {
  fireTrackingCall({
    event_name: "gotosea_search",
    campaign_id: "favorite",
    placement,
  });
}

export function trackViewSwitch(placement) {
  fireTrackingCall({
    event_name: "gotosea_search",
    campaign_id: "switch view",
    placement,
  });
}

export function trackSort(placement) {
  fireTrackingCall({
    event_name: "gotosea_search",
    campaign_id: "sort",
    placement,
  });
}

export function trackSailingDetailFavorite(placement) {
  fireTrackingCall({
    event_name: "gotosea_sailing_detail",
    campaign_id: "favorite",
    placement,
  });
}

export function trackCheckAvailability(placement) {
  fireTrackingCall({
    event_name: "gotosea_sailing_detail",
    campaign_id: "check availability",
    placement,
  });
}

export function trackSearchCruises(placement) {
  fireTrackingCall({
    event_name: "gotosea_home",
    campaign_id: "search cruises",
    placement,
  });
}

export function trackBestValuesRegion(placement) {
  fireTrackingCall({
    event_name: "gotosea_home",
    campaign_id: "values by region",
    placement,
  });
}

export function trackCognitoSignup(placement) {
  fireTrackingCall({
    event_name: "gotosea_signup",
    campaign_id: "signup",
    placement,
  });
}
