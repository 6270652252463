/* eslint-disable */

// This file was originally copied from here:
// https://code.usnews.com/its/static-atlas/-/blob/940006db31e0ff17001e653982a5cd49e8c3ec05/src/components/util/cookie-consent/index.js

/* An enum of the categories and their OneTrust IDs. */
export const CookieCategories = {
    PERFORMANCE: 'C0002',
    FUNCTIONAL: 'C0003',
    TARGETING: 'C0004',
    SOCIAL_MEDIA: 'C0005',
};

/* List of US states with privacy laws that require us to show a privacy
 * choices button to the user */
export const usPrivacyStates = [
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'IA',
    'MT',
    'NE',
    'NH',
    'NJ',
    'OR',
    'TX',
    'UT',
    'VA',
];

const sleep = (ms = 100) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const waitForOneTrustToggleInfoDisplay = async (waitLimit = 5000) => {
    let totalTime = 0;
    let lastTimestamp = new Date();
    while (totalTime < waitLimit) {
        if (typeof window !== 'undefined' && typeof window.OneTrust !== 'undefined' && typeof window.OneTrust.ToggleInfoDisplay !== 'undefined' && document.getElementById('onetrust-pc-sdk') !== null) {
            return window.OneTrust.ToggleInfoDisplay;
        }
        await sleep();
        let newTimestamp = new Date();
        totalTime += newTimestamp - lastTimestamp;
        lastTimestamp = newTimestamp;
    }
};

export const waitForOneTrustGeolocation = async (waitLimit = 3000) => {
    let totalTime = 0;
    let lastTimestamp = new Date();
    while (totalTime < waitLimit) {
        if (typeof window !== 'undefined' && typeof window.OneTrust !== 'undefined' && typeof window.OneTrust.getGeolocationData !== 'undefined') {
            return window.OneTrust.getGeolocationData();
        }
        await sleep();
        let newTimestamp = new Date();
        totalTime += newTimestamp - lastTimestamp;
        lastTimestamp = newTimestamp;
    }
};

/* Return true if the given categoryId is currently consented to. */
export const categoryIsConsentedTo = (categoryId) => {
    if (typeof window !== 'undefined' && typeof window.OnetrustActiveGroups !== 'undefined' && window.OnetrustActiveGroups.indexOf(categoryId) !== -1) {
        return true;
    }
    return false;
};

/* Return CSS class name to hide element until the given categories are
 * consented to.
 *
 * Note that this is not sufficient alone to hide the element. For script tags
 * you will also need set type="text/plain". For img and iframe tags you will
 * need to set data-src instead of src. */
export const getOneTrustCssClass = (categoryIds) => {
    return 'optanon-category-' + categoryIds.join('-');
};

// Cache of promises so we can just have one per category.
const promises = {};

/* Return a promise that will resolve when the single given category is
 * consented to. */
const getOrCreatePromise = (categoryId) => {

    if (Object.values(CookieCategories).indexOf(categoryId) === -1) {
        // Invalid category ID
        return null;
    }

    // Maintain one Promise per category ID
    if (promises.hasOwnProperty(categoryId)) {  // eslint-disable-line no-prototype-builtins
        return promises[categoryId];
    } else {
        let promise = new Promise((resolve) => {
            const recheckCategories = () => {
                if (categoryIsConsentedTo(categoryId)) {
                    resolve();

                    // Clean up event listener now that consent is given.
                    if (typeof window !== 'undefined') {
                        window.removeEventListener('onetrust-categories-updated', recheckCategories);
                    }
                }
            };

            if (categoryIsConsentedTo(categoryId)) {
                resolve();
            } else {

                // If consent hasn't already been given, we can wait for a change event and
                // and then re-check.
                if (typeof window !== 'undefined') {
                    window.addEventListener('onetrust-categories-updated', recheckCategories);
                }
            }
        });

        promises[categoryId] = promise;
        return promise;
    }
};

/* Return a promise that will resolve when all cookie categories in the given list
 * are consented to. */
export const waitForCookieConsent = (categoryIds) => {
    const promises = categoryIds.map(categoryId => getOrCreatePromise(categoryId));
    return Promise.all(promises);
};
